import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import ProjectCard from '../components/ProjectCard';
import ProjectModal from '../components/ProjectModal';
import axios from 'axios';
import logo from '../assets/logos/mit-logo.png'
import somcis1 from '../assets/projects/somcis1.jpeg'
import somcis2 from '../assets/projects/somcis2.jpeg'
import somcis3 from '../assets/projects/somcis3.jpg'
import somcis4 from '../assets/projects/somcis4.jpg'
import es1 from '../assets/projects/es1.jpeg'
import es2 from '../assets/projects/es2.jpeg'
import circle1 from '../assets/projects/circle1.jpeg'
import circle2 from '../assets/projects/circle2.jpeg'
import circle3 from '../assets/projects/circle3.jpg'
import circleFirstMeeting from '../assets/projects/circleFirstMeeting.pdf'
import theiaPitch from '../assets/projects/theiaPitch.pdf'
import theiaThumb from '../assets/projects/theiaThumb.jpg'
import theia1 from '../assets/projects/theia1.jpg'

const Milestones = () => {
  const [open, setOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);

  // Function to fetch dummy cat images
  const fetchCatImages = async (numImages) => {
    const response = await axios.get(`https://api.thecatapi.com/v1/images/search?limit=${numImages}`);
    return response.data.map((cat) => ({
      original: cat.url,
      thumbnail: cat.url,
    }));
  };

  // Function to initialize the project data with cat images
  const initializeProjects = async () => {
    const projects = [
      {
        title: 'Outstanding Student Award',
        description: 'Received the receiving the Outstanding Student Award, recognizing the top 1% of students with the most distinguished engineering trajectories at Tecnológico de Monterrey.',
        image: es1, //'https://via.placeholder.com/345x140',  // Placeholder for the main card image
        gallery: [
          { original: es1, thumbnail: es1 },
          { original: es2, thumbnail: es2 }
        ],
      },
      {
        title: 'Social Robotics Group',
        description: 'Co-founded a group to help children with ASD using social robots.',
        image: somcis2,  // Placeholder for the main card image
        gallery: [
          { original: somcis1, thumbnail: somcis1 },
          { original: somcis2, thumbnail: somcis2},
          { original: somcis3, thumbnail: somcis3},
          { original: somcis4, thumbnail: somcis4}
        ],
      },
      {
        title: 'B2B SaaS Startup',
        description: "Co-founded 'Circle', a B2B SaaS startup that helps companies explore blue ocean markets and match them with the right partners.",
        image: circle3,
        gallery: [
          { original: circle1, thumbnail: circle1 },
          { original: circle2, thumbnail: circle2},
          { original: circle3, thumbnail: circle3}
        ],
        slides: circleFirstMeeting
      },
      {
        title: 'Finalist at Hult Prize',
        description: "Finalist at Hult Prize Challenge Gdl 2024 with 'TheiaVision'. Developed a solution to help prevent blindness in diabetic patients.",
        link: '/projects/metflix',
        image: theia1,
        gallery: [
          { original: theiaThumb, thumbnail: theiaThumb },
          { original: theia1, thumbnail: theia1 },
        ],
        slides: theiaPitch,
        media: ['https://www.linkedin.com/posts/simon-amador_healthtech-healthtech-activity-7170447918171176960-Bq0R', 'https://www.instagram.com/p/C5FRU0xuVo1/?igsh=Mmc0OXg5eGdiY2Fx']
      },
      {
        title: 'Two Times National Winner',
        description: 'Won the FIRST national robotics competition twice, representing Tecnológico de Monterrey.',
        link: '/projects/metflix',
        image: 'https://conecta.tec.mx/sites/default/files/inline-images/FIRST%20Laguna66.webp',
        gallery: [
          { original: 'https://conecta.tec.mx/sites/default/files/inline-images/FIRST%20Laguna66.webp', thumbnail: 'https://conecta.tec.mx/sites/default/files/inline-images/FIRST%20Laguna66.webp' },
        ],
        media: ['https://conecta.tec.mx/es/noticias/guadalajara/educacion/se-une-al-mundial-de-robotica-stingbots-6702-de-prepatec-santa-anita', 
                'https://www.hidrocalidodigital.com/alumnos-de-prepa-tec-aguascalientes-ganan-su-pase-al-mundial-de-robotica/',
                'https://www.facebook.com/watch/?v=493793359116492']
      },
      
    ];

    // Fetch cat images for each project gallery
    // const updatedProjects = await Promise.all(
    //   projectsData.map(async (project) => {
    //     const gallery = await fetchCatImages(3);  // Fetch 3 cat images per project
    //     return { ...project, gallery };
    //   })
    // );

    setProjects(projects);
    
  };

  useEffect(() => {
    initializeProjects();
  }, []);

  const handleLearnMore = (project) => {
    setSelectedProject(project);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProject(null);
  };

  return (
    <Box sx={{ padding: '2rem' }}>
      <Typography variant="h4" gutterBottom style={{ marginBottom: '2rem' }}>
        Milestones
      </Typography>
      <Grid container spacing={4}>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ProjectCard {...project} onLearnMore={() => handleLearnMore(project)} outline />
          </Grid>
        ))}
      </Grid>

      {/* ProjectModal for displaying project details */}
      <ProjectModal open={open} onClose={handleClose} project={selectedProject} />
    </Box>
  );
};

export default Milestones;