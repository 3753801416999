import React from 'react';
import { Card, CardMedia, CardContent, Typography, Button, CardActions, Box } from '@mui/material';

const ProjectCard = ({ title, description, image, onLearnMore, outline }) => {
  return (
    <Card
      sx={{
        maxWidth: 350,
        minHeight: 345,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: outline ? '0 0 10px 5px rgba(31,150,243, 0.1)' : 'none',
        overflow: 'hidden', // Ensure content stays within the card
        '&:hover img': {
          transform: 'scale(1.05)',
        },
      }}
      onClick={onLearnMore}
    >
      <CardMedia
        component="img"
        height="140"
        image={image}
        alt={title}
        sx={{
          transition: 'transform 0.3s ease-in-out',
        }}
      />
      <Box sx={{ flexGrow: 1 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </Box>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button size="small" color="primary" onClick={onLearnMore}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProjectCard;