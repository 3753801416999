import React from 'react';
import { Box } from '@mui/material';

const Main = ({sections}) => {
  return (
    <Box>
      {sections.map(({ id, component: SectionComponent }) => (
        <Box id={id} key={id} sx={{ minHeight: '100vh', padding: '2rem' }}>
          <SectionComponent />
        </Box>
      ))}
    </Box>
  );
}

export default Main;
