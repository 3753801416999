import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css'; // Timeline styles
import { Typography, Box, Link as MuiLink } from '@mui/material'; // Material-UI components
import WorkIcon from '@mui/icons-material/Work';
import ScienceRoundedIcon from '@mui/icons-material/ScienceRounded';
import './Experience.css'; // Custom styles for animations (optional)

const experiences = [
  {
    title: 'SWE Volunteer',
    company: <MuiLink href="https://comiteecologicointegral.org/acerca-de-ceiaire/" target="_blank" rel="noopener noreferrer">AIRE CEI foundation</MuiLink>,
    date: 'Dec 2022 – Feb 2023',
    location: 'Monterrey, Mexico',
    description: (
      <ul>
        <li>Did frontend for an air quality foundation.</li>
      </ul>
    ),
  },
  {
    title: 'Informal SWE Intern',
    company: <MuiLink href="https://colaborar.mx/" target="_blank" rel="noopener noreferrer">Colaborar.mx</MuiLink>,
    date: 'Aug 2022 – Dec 2023',
    location: 'Monterrey, Mexico',
    description: (
      <ul>
        <li>
          Did full-stack for a social impact startup focused on collaborative economy.
        </li>
      </ul>
    ),
  },  
  {
    title: 'SWE Volunteer',
    company: <MuiLink href="https://www.instagram.com/creesiendomx/" target="_blank" rel="noopener noreferrer">CREESIENDO foundation</MuiLink>,
    date: 'Sep 2022 – Nov 2022',
    location: 'Monterrey, Mexico',
    description: (
      <ul>
        <li>
          Developed the landing page for a foundation focused on children's education.
        </li>
      </ul>
    ),
  },//SWE Volunteer at CLISA foundation Feb 2022 - Jun 2022
  {
    title: 'SWE Volunteer',
    company: <MuiLink href="https://www.grupoclisa.com.mx/Sites/fundacionclisa/index.html" target="_blank" rel="noopener noreferrer">CLISA foundation</MuiLink>,
    date: 'Feb 2022 – Jun 2022',
    location: 'Monterrey, Mexico',
    description: (
      <ul>
        <li>
          Did data pipeline development for a foundation focused on bringing eye care to low-income communities.
        </li>
      </ul>
    ),
  },
];

const VolunteerExperience = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom style={{marginBottom: '2rem' }}>
        Volunteer Experience
      </Typography>
      <VerticalTimeline>
        {experiences.map((exp, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            date={exp.date}
            iconStyle={{ background: exp.background || 'rgb(33, 150, 243)', 
              color: '#fff' }}
            icon={exp.icon || <WorkIcon />}
          >
            {/* Position and Location in the same row */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" component="h3">
                {exp.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {exp.location}
              </Typography>
            </Box>

            {/* Company Name Below */}
            <Typography
              variant="subtitle1"
              component="p"
              style={{ fontStyle: 'italic', marginTop: '0.5rem' }}
            >
              {exp.company}
            </Typography>

            {/* Description */}
            <Typography variant="body1" component="div">
              {exp.description}
            </Typography>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </Box>
  );
};

export default VolunteerExperience;
