import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, Box, useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SchoolIcon from '@mui/icons-material/School';
import { Link as ScrollLink } from 'react-scroll'; // For smooth scrolling
import './Navbar.css';

const Navbar = ({ sections }) => {
  const [anchorEl, setAnchorEl] = useState(null);   // For desktop "OTHER" menu
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);  // For mobile menu

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));  // Use MUI breakpoints for mobile

  // Handle mobile menu open/close
  const handleMobileMenuClick = (event) => {
    setMobileAnchorEl(event.currentTarget);  // Open mobile menu
  };

  const handleMenuClose = () => {
    setMobileAnchorEl(null);  // Close mobile menu
  };

  return (
    <Box>
      <AppBar position="fixed" sx={{ width: '100vw', left: 0, top: 0, backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(50px)', paddingRight: '50px' }}>
        <Toolbar sx={{ height: '64px' }}>
          {/* Branding with a school icon next to your name */}
          <Typography 
            variant="h6" 
            sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', paddingLeft: '30px' }}
          >
            <SchoolIcon sx={{ marginRight: '8px' }} />
            <p>Guillermo{' '}<b>Tafoya</b></p>
          </Typography>

          {/* Desktop Layout */}
          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              {sections.map((section) => (
                <Button
                  key={section.id}
                  color="inherit"
                  component={ScrollLink}
                  to={section.id}
                  smooth
                  duration={500}
                  spy
                  offset={-70}
                  className="navbar-item"
                  activeClass="active-section"
                  sx={{ height: '100%' }} // Ensure full height for the button
                >
                  {section.label}
                </Button>
              ))}
            </Box>
          )}

          {/* Mobile Layout - Hamburger Menu */}
          {isMobile && (
            <IconButton
              size="large"
              edge="end"  // Align the hamburger icon to the right
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenuClick}
              sx={{ ml: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}

          {/* Mobile Menu */}
          <Menu
            anchorEl={mobileAnchorEl}
            open={Boolean(mobileAnchorEl)}
            onClose={handleMenuClose}
            sx={{ mt: '45px' }}  // Adjust position below the AppBar
          >
            {sections.map((section) => (
              <MenuItem
                key={section.id}
                onClick={() => {
                  handleMenuClose();
                  const scrollToSection = document.getElementById(section.id);
                  if (scrollToSection) {
                    scrollToSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }
                }}
              >
                {section.label}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
