import React from 'react';
import { Box, Link as MuiLink } from '@mui/material';

const ResourceLinks = ({ slides, media, document, certificate, poster }) => {
  // Helper function to render either a single link or multiple links
  const renderLinks = (label, data) => {
    if (!data) return null; // If no data, return null

    if (Array.isArray(data)) {
      // Render multiple links if data is an array
      return data.map((item, index) => (
        <MuiLink key={index} href={item} target="_blank" rel="noopener noreferrer" sx={{ marginRight: 2 }}>
          [{label} {index + 1}]
        </MuiLink>
      ));
    }

    // Render a single link if data is not an array
    return (
      <MuiLink href={data} target="_blank" rel="noopener noreferrer" sx={{ marginRight: 2 }}>
        [{label}]
      </MuiLink>
    );
  };

  return (
    <Box sx={{ marginTop: 1, paddingLeft: 2, display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
      {/* Render links conditionally */}
      {renderLinks('Slides', slides)}
      {renderLinks('Media', media)}
      {renderLinks('Document', document)}
      {renderLinks('Certificate', certificate)}
      {renderLinks('Poster', poster)}
    </Box>
  );
};

export default ResourceLinks;
