import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import ProjectCard from '../components/ProjectCard';
import ProjectModal from '../components/ProjectModal';
import axios from 'axios';
import poli1 from '../assets/projects/poli1.jpeg'
import poli2 from '../assets/projects/poli2.jpeg'
import poli3 from '../assets/projects/poli3.jpeg'
import poli4 from '../assets/projects/poli4.jpeg'
import poli5 from '../assets/projects/poli5.jpeg'

const Projects = () => {
  const [open, setOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);

  // Function to fetch dummy cat images
  const fetchCatImages = async (numImages) => {
    const response = await axios.get(`https://api.thecatapi.com/v1/images/search?limit=${numImages}`);
    return response.data.map((cat) => ({
      original: cat.url,
      thumbnail: cat.url,
    }));
  };

  // Function to initialize the project data with cat images
  const initializeProjects = async () => {
    const projects = [
      {
        title: 'Picosatellite Development',
        description: 'Made a picosatellite to measure weather data.',
        image: 'https://www.heraldo.mx/wp-content/uploads/2018/11/PICOSATELITE-SERGIO-opt.jpg',
        gallery: [
          { original: 'https://www.heraldo.mx/wp-content/uploads/2018/11/PICOSATELITE-SERGIO-opt.jpg', thumbnail: 'https://www.heraldo.mx/wp-content/uploads/2018/11/PICOSATELITE-SERGIO-opt.jpg' },
        ],
        media: 'https://www.heraldo.mx/dan-muestra-de-su-talnto-en-una-mision-espacial/',
      },
      {
        title: 'Expociencias 2020',
        description: 'Made an smart garden that helped bees pollinate more efficiently.',
        image: poli2,
        gallery: [
          { original: poli1, thumbnail: poli1 },
          { original: poli2, thumbnail: poli2},
          { original: poli3, thumbnail: poli3},
          { original: poli4, thumbnail: poli4},
          { original: poli5, thumbnail: poli5}
        ],
        media: 'https://www.elsoldelcentro.com.mx/doble-via/ecologia/intentan-salvar-a-polinizadores-4388119.html'
      }
      //   title: 'User Stories Trouble Prediction',
      //   description: "Leveraged NLP to predict user stories that will cause trouble in the future for Cemex's teams.",
      //   image: 'https://via.placeholder.com/345x140',
      //   gallery: [
      //     { original: logo, thumbnail: logo },
      //     { original: logo, thumbnail: logo}
      //   ],
      //   slides: 'https://slides.com',
      // },
      // {
      //   title: 'MSL DuoLingo',
      //   description: "Made a gamification system for an enterprise (NDA) to help their teams become more inclusive by teaching them Industrial Mexican Sign Language.",
      //   image: 'https://via.placeholder.com/345x140',
      //   gallery: [
      //     { original: logo, thumbnail: logo },
      //     { original: logo, thumbnail: logo}
      //   ],
      //   slides: 'https://slides.com',
      // },
      // {
      //   title: 'SCRUM Gamification', 
      //   description: "Made a gamification system to help Cemex's teams learn SCRUM.",
      //   image: 'https://via.placeholder.com/345x140',
      //   gallery: [
      //     { original: logo, thumbnail: logo },
      //     { original: logo, thumbnail: logo}
      //   ],
      //   slides: 'https://slides.com',
      // },
      // {
      //   title: 'Hack Harvard 2023',
      //   description: 'Made a web app for education.',
      //   image: 'https://via.placeholder.com/345x140',
      //   gallery: [
      //     { original: logo, thumbnail: logo },
      //     { original: logo, thumbnail: logo}
      //   ],
      //   slides: 'https://slides.com',
      // },
      // {
      //   title: 'Hack Banorte 2022',
      //   description: 'Finalist by doing an AI web app for user banking experience.',
      //   image: 'https://via.placeholder.com/345x140',
      //   gallery: [
      //     { original: logo, thumbnail: logo },
      //     { original: logo, thumbnail: logo}
      //   ],
      //   slides: 'https://slides.com',
      // },
      // {
      //   title: 'Vivaaerobus Datathon 2022',
      //   description: 'Finalist by doing an AI web app to process customer feedback in an interactive way.',
      //   image: 'https://via.placeholder.com/345x140',
      //   gallery: [
      //     { original: logo, thumbnail: logo },
      //     { original: logo, thumbnail: logo}
      //   ],
      //   slides: 'https://slides.com',
      // },
      // {
      //   title: 'DFA Lexer',
      //   description: 'Made a DFA Lexer using Python',
      //   image: 'https://via.placeholder.com/345x140',
      //   gallery: [
      //     { original: logo, thumbnail: logo },
      //     { original: logo, thumbnail: logo}
      //   ],
      //   slides: 'https://slides.com',
      // },
      // {
      //   title: 'Metflix C++',
      //   description: 'A streaming platform based on C++, using QT.',
      //   image: 'https://via.placeholder.com/345x140',
      //   gallery: [
      //     { original: logo, thumbnail: logo },
      //     { original: logo, thumbnail: logo}
      //   ],
      //   slides: 'https://slides.com',
      // },
      // {
      //   title: 'Password Manager',
      //   description: 'A password manager using Python and Tkinter. GUI and CLI.',
      //   image: 'https://via.placeholder.com/345x140',
      //   gallery: [
      //     { original: logo, thumbnail: logo },
      //     { original: logo, thumbnail: logo}
      //   ],
      //   slides: 'https://slides.com',
      // },
      
      
    ];

    // Fetch cat images for each project gallery
    // const updatedProjects = await Promise.all(
    //   projectsData.map(async (project) => {
    //     const gallery = await fetchCatImages(3);  // Fetch 3 cat images per project
    //     return { ...project, gallery };
    //   })
    // );

    setProjects(projects);
    
  };

  useEffect(() => {
    initializeProjects();
  }, []);

  const handleLearnMore = (project) => {
    setSelectedProject(project);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProject(null);
  };

  return (
    <Box sx={{ padding: '2rem' }}>
      <Typography variant="h4" gutterBottom style={{ marginBottom: '2rem' }}>
        Projects
      </Typography>
      <Grid container spacing={4}>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ProjectCard {...project} onLearnMore={() => handleLearnMore(project)} />
          </Grid>
        ))}
      </Grid>

      {/* ProjectModal for displaying project details */}
      <ProjectModal open={open} onClose={handleClose} project={selectedProject} />
    </Box>
  );
};

export default Projects;