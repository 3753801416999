import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton, Grid, Box, Link as MuiLink } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ResourceLinks from './ResourceLinks';


// Fullscreen dialog for the clicked image
const FullScreenImageDialog = ({ open, imageUrl, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <DialogContent>
        <img src={imageUrl} alt="Full size" style={{ width: '100%', height: 'auto' }} />
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

const ProjectModal = ({ open, onClose, project }) => {
  const [fullScreenImage, setFullScreenImage] = useState(null);

  if (!project) return null;

  // Open the full-screen dialog for the clicked image
  const handleImageClick = (imageUrl) => {
    setFullScreenImage(imageUrl);
  };

  const handleFullScreenClose = () => {
    setFullScreenImage(null);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" fontWeight="bold" style={{ paddingLeft: '1rem' }}>
            {project.title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph style={{ paddingLeft: '1rem' }}>
            {project.description}
          </Typography>
          <Typography variant="body2" paragraph>
            <ResourceLinks slides={project.slides} media={project.media} document={project.document} certificate={project.certificate} poster={project.poster} />
          </Typography>

          {/* Styled grid for gallery images */}
          <Grid container spacing={2} justifyContent="center">
            {project.gallery.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                {/* Container for each image */}
                <Box
                  sx={{
                    width: '100%',              // Image will try to fill its parent width
                    maxHeight: '300px',          // Max height of the container
                    minHeight: '300px',          // Min height to maintain consistency
                    position: 'relative',        // Required for absolute positioning of zoom button
                    display: 'flex',             // Flexbox for image centering
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',          // Prevent image overflow
                    backgroundColor: '#f0f0f0',  // Light background for contrast
                  }}
                >
                  <img
                    src={item.original}
                    alt=""
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',  // Maintain aspect ratio, fit inside the container
                    }}
                    onClick={() => handleImageClick(item.original)}
                  />
                  <IconButton
                    onClick={() => handleImageClick(item.original)}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                    }}
                  >
                    <ZoomInIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Fullscreen image dialog */}
      {fullScreenImage && (
        <FullScreenImageDialog open={!!fullScreenImage} imageUrl={fullScreenImage} onClose={handleFullScreenClose} />
      )}
    </>
  );
};

export default ProjectModal;
